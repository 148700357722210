import React from "react";
import './style.css'

const UserInformation = () => {
  return (
    <div className="privacy-container">
      {/* <p>Güncellenme tarihi: 20.06.2022</p> */}
      <h1>ALOHA LIVE GİZLİLİK POLİTİKASI (Aydınlatma Metni)</h1>
      <h2>
        Kullanıcılık ve Dinleyicilik Süreçlerinde İşlenen Verilerinize İlişkin
        Aydınlatma Metni
      </h2>
      <p>
        İşbu Kullanıcı Aydınlatma Metni’nin amacı, Barbaros Mahallesi Begonya
        Sokak Nidakule Ataşehir Batı Blok No:1 İç Kapı No:2 Ataşehir İstanbul
        merkezli veri sorumlusu{" "}
        <strong>
          Aloha Dijital Bilişim Eğitim ve Danışmanlık Anonim Şirketi (“Veri
          Sorumlusu”, “Şirket” veya “Aloha”)
        </strong>{" "}
        tarafından yönetilmekte olan{" "}
        <a className="text-dark fw-bold" href="https://alohalive.online/">
          alohalive.online
        </a>{" "}
        adresli internet sitesinin ve AlohaLive uygulamasının{" "}
        <strong>(“Uygulamalar”)</strong>
        kullanıcıları, dinleyicileri ve ziyaretçileri{" "}
        <strong>
          (“Kullanıcı/lar (Kullanıcı Girişi & Dinleyişi Girişi)” veya “Siz”)
        </strong>{" "}
        tarafından, Şirket tarafından Uygulamalar aracılığı ile sağlanan
        hizmetler <strong>(“Hizmetler”)</strong> için Şirket’e sağlanan ve/veya
        Uygulamalar’ın kullanımı aşamasında Kullanıcılar’dan ve Uygulama
        ziyaretçilerinden elde edilen kişisel verilerin işlenmesine ilişkin
        koşullar hakkında Kullanıcılar’ı bilgilendirmek ve aydınlatmaktır. Bu
        Metin’de tanımlanmayan ifadelerin yorumlanmasında Uygulamalar’da
        yayımlanan ve imzanıza sunulan Kullanıcı Sözleşmesi’ndeki{" "}
        <strong>(“Sözleşme”)</strong> tanımlamalar dikkate alınacaktır.
      </p>
      <p>
        Uygulamalar’ı kullanırken kişisel verileriniz için kendinizi güvende
        hissedebilirsiniz. Ancak, lütfen unutmayınız ki hiçbir sistem tamamıyla
        güvenli değildir ve daima insan faktörü gündeme gelebilir. Verilerinizi
        korumak için gerekli tüm adımları atsak dahi daima, güvence dışında
        kalma ihtimaliniz söz konusudur. Bu sebeple kişisel verilerinizi
        paylaşırken çok dikkat etmeniz gerektiğini hatırlatmak isteriz. Şirket
        tarafından talep edilen veriler dışında Şirket’in talebi olmaksızın
        sizin tarafınızdan iletilen verilerin işlenmesinin takibi mümkün
        olmadığından, işbu Metin kapsamında Şirket tarafından işlenen kişisel
        veriler olarak sayılmayacaktır.{" "}
        <strong>
          Bu sebeple Şirket tarafından talep edilmeyen diğer kişisel
          verilerinizi paylaşmamanız gerektiğini önemle ihtar ederiz. Kullanıcı
          sıfatıyla Hizmetler’i kullanırken anonim kalabilirsiniz.
        </strong>
      </p>
      <div style={{ margin: "40px" }}>
        <p>
          Kanun’un 3. ve 7. maddeleri uyarınca, geri döndürülemeyecek şekilde
          anonim hale getirilen veriler, Kanun uyarınca kişisel veri olarak
          kabul edilmeyecek ve bu verilere ilişkin işleme faaliyetleri işbu
          Metin ve Politika hükümleri ile bağlı olmaksızın
          gerçekleştirilecektir. Kullanıcı, işbu Metin ve Politika’ya konu olan
          bilgilerinin tam, doğru ve güncel olduğunu, bu bilgilerde herhangi bir
          değişiklik olması halinde bunları derhal güncelleyeceğini taahhüt
          eder. Kullanıcı’nın güncel bilgilerini paylaşmamasından kaynaklanan
          durumlardan Şirket’in herhangi bir sorumluluğu söz konusu
          olmayacaktır.{" "}
          <strong>
            ALOHA, Üyenin hassas olarak (özel nitelikli kişisel veri)
            nitelendirilebilecek verileri Dinleyici ile veya Uygulama
            aracılığıyla ALOHA ile paylaşmamasını tavsiye eder.
          </strong>
        </p>
      </div>

      <h3>
        1. Süreçte İşlenen Kişisel Verileriniz, İşleme Amaçları ve Hukuki
        Sebepleri Hakkında Ayrıntılı Bilgilendirme
      </h3>
      <h3>
        1.1. Hizmetler’den Konuşmacı Girişini Kullanan Uygulama Kullanıcılarının
        Verileri
      </h3>
      <h4 style={{ textDecoration: "underline" }}>
        Üyelik Kaydı İçin Toplanan Veriler
      </h4>
      <p style={{ textAlign: "center" }}>
        <em>
          **İşleme Amacını Gösteren Ana Süreçlerde, Veri Sorumluları Bilgi Sicil
          Sistemi Sınıflandırmaları Kullanılmıştır. Şirketimiz, işbu metnin
          yayınlandığı tarihte, VERBİS sorumlusu değildir; ancak veri
          envanterleri oluşturulmuş, mevzuat kapsamındaki tüm önlemler
          alınmıştır.
        </em>
      </p>
      <ul>
        <li>
          <strong>Kimlik verilerinden;</strong> kullanıcı adı (gerçek isminizi
          kullandığınız hallerde) Kullanıcılar gerçek ismini kullanmak zorunda
          değildir. Aloha, sohbetlere anonim katılımınızı önermektedir.
        </li>
        <li>
          <strong>Bu kategoride sıralanan verileriniz,</strong> “Bir sözleşmenin
          kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla,
          sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli
          olması” ve İlgili kişinin temel hak ve özgürlüklerine zarar vernemek
          kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin
          zorunlu olması hukuki sebebi kapsamında, Mal / Hizmet Satış
          Süreçlerinin Yürütülmesi, Mal / Hizmet Üretim ve Operasyon
          Süreçlerinin Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi,
          Sözleşme Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
          Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi ana sürecinde;
          üyelik kaydınızın/başvurunuzun alınması, kullanıcı ve dinleyici
          arasındaki iletişimin kurulması, öneri, şikayet ve sorularınızı kayıt
          altına alabilmek, sözleşmelerin akdedilmesi ve güncellenmesi,
          arşivlenmesi amaçlarıyla işlenmektedir.
        </li>
        <li>
          <strong>İletişim verilerinizden;</strong> elektronik posta adresi
        </li>
        <li>
          <strong>Bu kategoride sıralanan verileriniz,</strong> “İlgili kişinin
          temel hak ve özgürlüklerine zarar vernemek kaydıyla, veri sorumlusunun
          meşru menfaatleri için veri işlenmesinin zorunlu olması” hukuki sebebi
          kapsamında, Mal / Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi
          ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin
          Yürütülmesi, Saklama ve Arşiv Faaliyetlerinin Yürütülmesi, Bilgi
          Güvenliği Süreçlerinin Yürütülmesi, Veri Sorumlusu Operasyonlarının
          Güvenliğinin Temini ana süreçlerinde, üyelik ve sözleşme güncelleme
          süreçlerinde kimlik doğrulama süreçlerinin icrası, Şirket ve
          dinleyicilerle olan iletişiminizin sağlanması, ticari iletişim
          süreçleri, öneri, şikayet ve sorularınızı kayıt altına alabilmek,
          sözleşmelerin akdedilmesi ve güncellenmesi, arşivlenmesi amaçlarıyla
          işlenmektedir.
        </li>
      </ul>
      <ul>
        <p style={{ textDecoration: "underline" }}>
          <strong>
            Hizmetlerin Kullanılması Sırasında İşlenen Verileriniz
          </strong>
        </p>
        <li>
          <strong>İşlem güvenliği verilerinizden;</strong> Hizmetler’in
          görülmesi esnasında veya Hizmetler için yapılan her türlü yazışma vb.,
          sayfada gezinme ve/veya kullanım oturumuna ait veriler (IP adresi,
          coğrafi konum, benzersiz cihaz tanımlayıcı vb., Uygulama üzerindeki
          faaliyetlerinize ilişkin veriler, şifre verisi) <br />
          <br />{" "}
          <strong style={{ textDecoration: "underline" }}>
            Bu kategoride sıralanan verileriniz,
          </strong>{" "}
          “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
          veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
          olması”hukuki sebebi kapsamında, Saklama ve Arşiv Faaliyetlerinin
          Yürütülmesi, Bilgi Güvenliği Süreçlerinin Yürütülmesi, Veri Sorumlusu
          Operasyonlarının Güvenliğinin Temini amaçlarıyla işlenmektedir.
        </li>
        <p>
          <strong>
            <em>Konuşmacı Kartı Oluşturulması</em>
          </strong>
        </p>
        <p>
          Konuşmacı’nın, kimliğini belirlemeye yarayan verileri sisteme kaydı
          zorunlu değildir. Konuşmacı, ayırt edici kişisel bilgilerini kendi
          isteğiyle Dinleyici’ye bildirirse, bu bilgiler, Aloha Kişisel
          Verilerin Korunması Politikasına ve işbu Aydınlatma Metnine uygun
          şekilde işlenecek ve arşivlenecektir.
        </p>
        <p>
          Konuşmacı’ya, ilettiği bilgilere göre bir kullanıcı kartı düzenlenir.
          Konuşmacı kartında, temel olarak; yaş, cinsiyet, duygu durumu,
          kullanıcı adı, sohbet dili, ilgilendiği konular ve sohbet süresi yer
          alır. Konuşmacı, tercihine göre cinsiyet, yaş sekmelerini boş
          bırakabilir. Kullanıcı, dinleyici ile konuşması sırasında bu
          bilgilerinin gizlenmesi için düzenleme yapabilir.
        </p>
        <p>Bu süreçte;</p>
        <ul>
          <li>
            <strong>Kimlik verilerinden;</strong>
          </li>
          <ul>
            <li>
              kullanıcı adı (gerçek isminizi kullandığınız hallerde)
              Konuşmacılar gerçek ismini kullanmak zorunda değildir. Aloha,
              sohbetlere anonim katılımınızı önermektedir.
            </li>
            <li>yaş</li>
            <li>cinsiyet</li>
            <p>
              <strong>Bu kategoride sıralanan verileriniz,</strong> “Bir
              sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
              kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması” ve İlgili kişinin temel hak ve
              özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
              menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebi
              kapsamında, Mal / Hizmet Satış Süreçlerinin Yürütülmesi, Mal /
              Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi ve İletişim
              Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
              Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi ana sürecinde; üyelik
              kaydınızın/başvurunuzun alınması, kullanıcı ve dinleyici
              arasındaki iletişimin kurulması, öneri, şikayet ve sorularınızı
              kayıt altına alabilmek, sözleşmelerin akdedilmesi ve güncellenmesi
              ve arşivlenmesi ile sözleşmeyi gereği gibi ifa edebilmek
              amaçlarıyla işlenmektedir.
            </p>
          </ul>
          <li>
            <strong>Diğer Verilerinden;</strong>
          </li>
          <p>
            Hizmetler İçin Zorunlu Olanlar “Z” ile işaretlenmiştir. Sıralanan
            verilerden zorunlu olanlar dışındakileri paylaşmak istemiyorsanız
            “Bu Adımı Geç” butonuna tıklayınız. Paylaşmanız halinde, işleme
            süreçleri aşağıda açıklandığı şekilde yürütülecektir.
          </p>
          <ul>
            <li>duygu durumu</li>
            <li>sohbet süresi (Z)</li>
            <li>favorilerine aldığı dinleyici sayısı (Z)</li>
            <li>ilgilendiği konular (Z)</li>
            <li>feedback (geri dönüş yorumları) sekmesinde işlenen veriler</li>
            <li>dinleyici değerlendirmeleri</li>
          </ul>
          <p>● Mesleki Deneyim Verilerinden;</p>
          <ul>
            <li>meslek (Konuşmacı, meslek verisini boş bırakabilir.)</li>
          </ul>
          <p>
            <strong>Bu kategoride sıralanan verileriniz,</strong> İlgili kişinin
            temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
            sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması
            ve sözleşmenin ifasıyla doğrudan doğruya ilgili olması hukuki sebebi
            kapsamında; Mal / Hizmet Satış Süreçlerinin Yürütülmesi, Mal /
            Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi ve İletişim
            Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
            Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi, konuşmacı ve dinleyici
            arasındaki iletişimin kurulması, sözleşmenin gereği gibi ifasının
            sağlanması, öneri, şikayet ve sorularınızı kayıt altına alabilmek,
            dinleyici eğitimlerinin konuşmacı kitlesine göre güncelliğinin
            korunması, sözleşmelerin akdedilmesi ve güncellenmesi, arşivlenmesi
            amaçlarıyla işlenmektedir.
          </p>
          <p>
            <strong>● Müşteri İşlem Verilerinizden;</strong>
          </p>
          <ul>
            <li>
              Satın aldığınız ve kullandığınız ALC Coin sayısı, satın alma
              tarihi ve miktarı, bakiye bilgileri, sözleşme ve onay metinleri.
            </li>
          </ul>
          <p>
            <em>
              ! Kullanıcı’nın kredi kartı/banka kartı bilgilerine ALOHA
              erişemez. Ödeme altyapısı Play Store ve App Store tarafından
              sağlanmaktadır.
            </em>
          </p>
          <p>
            <strong>Bu kategoride sıralanan verileriniz,</strong> “Bir
            sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması
            kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin
            gerekli olması” ve İlgili kişinin temel hak ve özgürlüklerine zarar
            vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri
            işlenmesinin zorunlu olması hukuki sebebi kapsamında, Mal / Hizmet
            Satış Süreçlerinin Yürütülmesi, Mal / Hizmet Üretim ve Operasyon
            Süreçlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi, Finans
            Ve Muhasebe İşlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi ana sürecinde;
            kullanım haklarınızın tespiti ve takibi ve tanıtım faaliyetleri,
            iletişim faaliyetlerinin yönetilmesi amacıyla işlenmektedir.
          </p>
          <p>
            <strong>
              <em>Şikâyet, Talep ve Anketler Vasıtasıyla İşlenen Veriler</em>
            </strong>
          </p>
          <p>
            <strong>● Müşteri İşlem Verilerinden;</strong>
          </p>
          <ul>
            <li>Şikâyet veya talebin tarihi ve saati</li>
            <li>Anket cevapları</li>
            <li>Şikâyet veya talebin mahiyeti ve sebebi</li>
            <li>Şikâyet edilen kullanıcıya ilişkin veriler</li>
          </ul>
          <p>
            <strong>● Kimlik verilerinden;</strong>
          </p>
          <ul>
            <li>
              Şikâyet konusunun resmi mercilere haber verilmesini gerektirir
              olması halinde, kimliğinizi kanıtlayan bir belge
            </li>
            <li>
              Kişisel Verilerinizin Korunması Kanununun 11. Maddesi kapsamında
              yaptığımız başvurularda kimliğinizi kanıtlayan belge
            </li>
            <li>
              Hukuki uyuşmazlık süreçlerinin gündeme gelmesi halinde kimliğinizi
              kanıtlayan belge
            </li>
          </ul>
          <p>
            <strong>Bu kategorilerde sıralanan verileriniz,</strong> “İlgili
            kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
            sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması
            ve Bir hakkın tesisi, kullanılması veya korunması için veri
            işlemenin zorunlu olması hukuki sebebi kapsamında, Mal / Hizmet
            Üretim ve Operasyon Süreçlerinin Yürütülmesi ve İletişim
            Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
            Saklama ve Arşiv Faaliyetlerinin Yürütülmesi, Faaliyetlerin Mevzuata
            Uygun Yürütülmesi, Yetkili Kamu Kurum ve Kuruluşlara bilgi verilmesi
            ana sürecinde; öneri, şikayet ve sorularınızı kayıt altına
            alabilmek, yetkili kurum ve kuruluşlara bilgi verebilmek, kimlik
            doğrulaması yapabilmek, hukuka aykırılıkları
          </p>
        </ul>
      </ul>
      <h3>
        1.2.İşlenen Verilerden Yalnızca Dinleyici Sıfatını Taşıyanlar İçin
      </h3>
      <h4 style={{ textDecoration: "underline" }}>
        Üyelik Kaydı İçin İşlenen Veriler
      </h4>
      <div>
        <ul>
          <li>
            {" "}
            <strong>Kimlik Verilerinden;</strong> kullanıcı adı Ad-Soyad
            (Dinleyiciler Uygulama’ya anonim katılabilirler, gerçek isimlerini
            bildirmeleri zorunlu değildir)
          </li>
          <li>Doğum tarihi (Dinleyici tarafından bildirilmesi halinde)</li>
          <li style={{ listStyle: "none" }}>
            {" "}
            Bu kategoride sıralanan verileriniz,{" "}
            <em>
              “Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili
              olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin
              işlenmesinin gerekli olması” ve ilgili kişinin temel hak ve
              özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
              menfaatleri için veri işlenmesinin zorunlu olması
            </em>{" "}
            hukuki sebebi kapsamında, Mal/Hizmet Üretim Ve Operasyon
            Süreçlerinin Yürütülmesi, Mal/Hizmet Satın Alım ve Satış
            Süreçlerinin Yürütülmesi, İletişim Faaliyetlerinin Yürütülmesi,
            Sözleşme Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Finans ve
            Muhasebe İşlerinin Yürütülmesi ana sürecinde; dinleyicilik sıfatının
            kazanılması, dinleyici ve kullanıcılar arasındaki iletişimin
            kurulması, sözleşmelerin akdedilmesi ve güncellenmesi, arşivlenmesi,
            faturalama ve arşivleme işlemlerinin yürütülmesi, veri sorumlusu
            operasyonlarının güvenliğinin temini, performans değerlendirme
            süreçlerinin yürütülmesi, öneri, şikayet ve sorularınızı kayıt
            altına alabilmek amaçlarıyla işlenmektedir.
          </li>
          <li>
            <strong>İletişim verilerinizden;</strong> elektronik posta adresi
            <p>
              <strong style={{ textDecoration: "underline" }}>
                Bu kategoride sıralanan verileriniz,
              </strong>{" "}
              “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
              kaydıyla, veri sorumlusunun meşru menfaatleri için veri
              işlenmesinin zorunlu olması” hukuki sebebi kapsamında, Mal/Hizmet
              Üretim Ve Operasyon Süreçlerinin Yürütülmesi ve İletişim
              Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
              Saklama Ve Arşiv Faaliyetlerinin Yürütülmesi, Bilgi Güvenliği
              Süreçlerinin Yürütülmesi, Veri Sorumlusu Operasyonlarının
              Güvenliğinin Temini ana süreçlerinde, üyelik ve sözleşme
              güncelleme süreçlerinde kimlik doğrulama süreçlerinin icrası,
              Şirket ve konuşmacılarla olan iletişiminizin sağlanması, ticari
              iletişim süreçleri, öneri, şikayet ve sorularınızı kayıt altına
              alabilmek, sözleşmelerin akdedilmesi ve güncellenmesi,
              arşivlenmesi amaçlarıyla işlenmektedir.
            </p>
          </li>
          <li>
            <strong>Diğer verilerinizden;</strong> online eğitim tamamlama
            bilgisi.
            <p>
              <strong style={{ textDecoration: "underline" }}>
                Bu kategoride sıralanan verileriniz,
              </strong>{" "}
              <em>
                “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
                kaydıyla, veri sorumlusunun meşru menfaatleri için veri
                işlenmesinin zorunlu olması”
              </em>{" "}
              hukuki sebebi kapsamında, Eğitim Faaliyetlerinin Yürütülmesi ve
              Sözleşme Süreçlerinin Yürütülmesi amaçlarıyla işlenmektedir.
            </p>
          </li>
        </ul>
      </div>
      <div>
        <p>
          <strong>
            Hizmetlerin Kullanılması Sırasında İşlenen Kişisel Verileriniz
          </strong>
        </p>
        <p>
          <strong>
            <em>Dinleyici Kartı Oluşturulması</em>
          </strong>
        </p>
        <p style={{ textAlign: "center" }}>
          <em>
            {" "}
            Dinleyici adayı; ad, soyad, kullanıcı adı, doğum tarihi, elektronik
            posta adresi ve şifre bilgilerini doldurarak ve sohbet konusunu
            seçerek sisteme kaydolur. Dinleyici, Aloha tarafından verilen eğitim
            ve onay sonrası dinleyici sıfatını kazanır ve kendisine Uygulama
            için kullanıcı adı ve şifre verilir ve dinleyici kartı oluşturulur.
          </em>{" "}
          <br />
          <br />
          <em>
            Dinleyici kartında, dinleyicinin; yaş, cinsiyet, duygu durumu,
            sohbet dili, uygulamaya kaç aydır üye olduğu, kullanıcı adı, kaç
            konuşmacının kendisine favorilerine eklediği, aktif edilmesi halinde
            hakkındaki yorumlar ve puan bilgisi yer alır.
          </em>
        </p>

        <ul>
          <p>Bu süreçte,</p>
          <li>
            Kimlik verilerinden;
            <p>-kullanıcı adı (Z)</p>
            <p>-yaş</p>
            <p>-cinsiyet verileri işlenir</p>
          </li>
          <p>
            Bu kategoride sıralanan verileriniz, “Bir sözleşmenin kurulması veya
            ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin
            taraflarına ait kişisel verilerin işlenmesinin gerekli olması” ve
            İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
            veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
            olması hukuki sebebi kapsamında, Mal / Hizmet Satış Süreçlerinin
            Yürütülmesi, Mal / Hizmet Üretim ve Operasyon Süreçlerinin
            Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi, Sözleşme
            Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
            Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi ana sürecinde;
            üyelik kaydınızın/başvurunuzun alınması, konuşmacı ve dinleyici
            arasındaki iletişimin kurulması, öneri, şikayet ve sorularınızı
            kayıt altına alabilmek, sözleşmenin gereği gibi ifası, sözleşmelerin
            akdedilmesi ve güncellenmesi, arşivlenmesi amaçlarıyla
            işlenmektedir.
          </p>
          <li>
            Diğer Verilerden;
            <p>-duygu durumu</p>
            <p>-dinleyiciyi favorilerine alan konuşmacı sayısı</p>
            <p>-ilgilendiği konular</p>
            <p>-feedback (geri dönüş yorumları) sekmesinde işlenen veriler</p>
            <p>-konuşmacı değerlendirmeleri</p>
          </li>
          <li>Mesleki Deneyim Verilerinden; -meslek</li>
          <p>
            Bu kategorilerde sıralanan verileriniz, İlgili kişinin temel hak ve
            özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru
            menfaatleri için veri işlenmesinin zorunlu olması hukuki sebebi
            kapsamında, Mal / Hizmet Satış Süreçlerinin Yürütülmesi, Mal /
            Hizmet Üretim ve Operasyon Süreçlerinin Yürütülmesi ve İletişim
            Faaliyetlerinin Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi,
            Saklama ve Arşiv Faaliyetlerinin Yürütülmesi, konuşmacı ve dinleyici
            arasındaki iletişimin kurulması, öneri, şikayet ve sorularınızı
            kayıt altına alabilmek, sözleşmelerin akdedilmesi ve güncellenmesi,
            arşivlenmesi amaçlarıyla işlenmektedir.
          </p>
          <ul>
            <li style={{ listStyle: "none" }}>
              <p>
                <strong>
                  <em>
                    Şikâyet, Talep ve Anketler Vasıtasıyla İşlenen Veriler
                  </em>
                </strong>
              </p>
            </li>
            <ul>
              <p>
                <strong>Müşteri İşlem Verilerinden;</strong>
              </p>
              <li>Şikâyet veya talebin tarihi ve saati</li>
              <li>Anket cevapları</li>
              <li>Şikâyet veya talebin mahiyeti ve sebebi</li>
              <li>Şikâyet edilen konuşmacıya ilişkin veriler</li>
            </ul>
            <ul>
              <p>
                <strong>Kimlik Verilerinden;</strong>
              </p>
              <li>
                Şikâyet konusunun resmi mercilere haber verilmesini gerektirir
                olması halinde, kimliğinizi kanıtlayan bir belge
              </li>
              <li>
                Kişisel Verilerinizin Korunması Kanununun 11. Maddesi kapsamında
                yaptığımız başvurularda kimliğinizi kanıtlayan belge
              </li>
              <li>
                Hukuki uyuşmazlık süreçlerinin gündeme gelmesi halinde
                kimliğinizi kanıtlayan belge
              </li>
              <p>
                <strong>Bu kategoride sıralanan verileriniz,</strong> “İlgili
                kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
                veri sorumlusunun meşru menfaatleri için veri işlenmesinin
                zorunlu olması” hukuki sebebi kapsamında, Mal/Hizmet Üretim ve
                Operasyon Süreçlerinin Yürütülmesi ve İletişim Faaliyetlerinin
                Yürütülmesi, Sözleşme Süreçlerinin Yürütülmesi, Saklama Ve Arşiv
                Faaliyetlerinin Yürütülmesi, Faaliyetlerin Mevzuata Uygun
                Yürütülmesi, Yetkili Kamu Kurum ve Kuruluşlara bilgi verilmesi
                ana sürecinde; öneri, şikâyeti ve sorularınızı kayıt altına
                alabilmek, yetkili kurum ve kuruluşlara bilgi verebilmek hukuka
                aykırılıkları önlemek ve gidermek ve ilgili işlemlerin
                arşivlenmesi amaçlarıyla işlenmektedir.
              </p>
            </ul>
          </ul>
        </ul>
      </div>
      <h3>
        1.3. Görsel ve İşitsel Veri Kategorisinden; İşitsel veriler (Konuşmacı,
        Dinleyici)
      </h3>
      <p>
        Uygulama üzerinde sesli sohbet seçeneği bulunmaktadır. Ancak sesiniz
        Uygulama’da herhangi bir şekilde tanımlama veya doğrulama işlemine tabi
        tutulmaz. Bu nedenle özel nitelikli kişisel veri kategorisinde değil, bu
        kategoride değerlendirilmiştir. Ancak, ses kaydınızın işlenmesini ve
        saklanmasını istemiyorsanız, lütfen yalnızca yazılı sohbet seçeneğini
        kullanın. Uygulama nezdinde sesli sohbetleriniz uçtan uca şifrelenerek
        saklanır
      </p>
      <p>
        <strong style={{ textDecoration: "underline" }}>
          Bu kategoride sıralanan verileriniz,
        </strong>{" "}
        “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
        veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
        olması”hukuki sebebi kapsamında, İletişim Faaliyetlerinin Yürütülmesi,
        Sözleşme Süreçlerinin Yürütülmesi amacıyla işlenmektedir.
      </p>

      <h3>1.4. Diğer Verilerinizden (Konuşmacı, Dinleyici)</h3>
      <p>
        <strong>Sohbet Verileri</strong>
      </p>

      <p style={{ textAlign: "center" }}>
        <em>
          İletişimin gizliliği esas olmakla birlikte acil durum bildirimi
          (taciz, hakaret içerikli yazışma, uygunsuz sohbet içeriğini, uzun
          süredir cevap alamama bildirimi, diğer) halinde, Dinleyici’nin
          şikâyeti üzerine, yahut gerekli görülen hallerde, admin sohbete davet
          edilebilir. Admin, sohbeti ilk adımda anonim olarak kontrol edecek,
          Sözleşme’ye yahut mevzuata aykırı bir durum varsa, ilgili bilgileri
          Şirket yetkililerine kişisel verileri de içerecek şekilde
          aktaracaktır. Admin, bu ziyareti görünmeden yapabilir veya Dinleyici
          ile ayrı bir ekranda iletişim kurabilir. Dinleyici, Aloha sistem
          yöneticisi veya görevlileri tarafından iletişimin denetlenmesi ve
          yasaya aykırılık şüphesi olması durumunda, iletişimin bir kopyasının
          saklanmasını, talep halinde resmi kurum ve kuruluşlarla paylaşılmasını
          kabul eder.
          <br />
          Yazılı mesajlar ve sesli mesajlar tamamıyla özeldir ve sadece ilgili
          kullanıcıları ilgilendirir. Ancak, mesajlar, adli talep ve/veya yasal
          düzenlemeler kapsamında verilerin saklanması ve yetkili kamu kurum ve
          kuruluşlarla paylaşılması gündeme gelebilir. Sohbet içerikleri uçtan
          uça şifrelenmektedir.
        </em>
      </p>
      <p>
        <strong>Bu kapsamda, bu kategoride sıralanan verileriniz,</strong>{" "}
        “İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla,
        veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
        olması hukuki sebebi kapsamında, Mal / Hizmet Üretim ve Operasyon
        Süreçlerinin Yürütülmesi ve İletişim Faaliyetlerinin Yürütülmesi,
        Sözleşme Süreçlerinin Yürütülmesi, Saklama Ve Arşiv Faaliyetlerinin
        Yürütülmesi, Faaliyetlerin Mevzuata Uygun Yürütülmesi, Yetkili Kamu
        Kurum ve Kuruluşlara bilgi verilmesi ana sürecinde; şikayetlerinizi
        kayıt altına alabilmek, yetkili kurum ve kuruluşlara bilgi verebilmek
        hukuka aykırılıkları önlemek ve gidermek ve ilgili işlemlerin
        arşivlenmesi amaçlarıyla işlenmektedir.
      </p>

      <p>
        <strong>Pazarlama Verilerinden;</strong>
      </p>
      <p>
        Alışveriş geçmişi, çerez kayıtları, anket kayıtları, kampanya
        çalışmaları ile elde edilen veriler. <br /> Çerezlerin türüne göre,
        işlemede kullanım amacı ve hukuki sebebi değişmektedir. Ayrıntılı bilgi
        için Çerez Aydınlatma Metnimize ulaşınız.
      </p>
      <ul style={{ listStyle: "none" }}>
        <em>
          <li>
            <strong>Bu kategoride sıralanan verileriniz,</strong> açık rızanız
            kapsamında,
          </li>
          <li>Denetim / Etik Faaliyetlerinin Yürütülmesi</li>
          <li>Faaliyetlerin Mevzuata Uygun Yürütülmesi</li>
          <li>Firma / Ürün / Hizmetlere Bağlılık Süreçlerinin Yürütülmesi</li>
          <li>
            İç Denetim/ Soruşturma / İstihbarat Faaliyetlerinin Yürütülmesi
          </li>
          <li>İletişim Faaliyetlerinin Yürütülmesi</li>
          <li>İş Faaliyetlerinin Yürütülmesi / Denetimi</li>
          <li>Müşteri İlişkileri Yönetimi Süreçlerinin Yürütülmesi</li>
          <li>Müşteri Memnuniyetine Yönelik Aktivitelerin Yürütülmesi</li>
          <li>Organizasyon Ve Etkinlik Yönetimi</li>
          <li>Pazarlama Analiz Çalışmalarının Yürütülmesi</li>
          <li>Reklam / Kampanya / Promosyon Süreçlerinin Yürütülmesi</li>
          <li>
            Ürün / Hizmetlerin Pazarlama Süreçlerinin Yürütülmesi ana
            süreçlerinde ticari iletişim ve pazarlama çalışmalarının yönetilmesi
            amacıyla işlenmektedir.
          </li>
        </em>
      </ul>
      <h3>2. Kişisel Verilerinizi Toplamanın Yöntemi Nedir?</h3>
      <p>
        Kişisel Verileriniz, kullanıcı kaydınız, hizmet talepleriniz, hizmetleri
        kullanımınız, web sitesi ve Uygulamayı ziyaretiniz sırasında tamamen
        veya kısmen otomatik yöntemlerle toplanmaktadır. <br />
        ALOHA web sitesinde, Site ziyaretçilerinin, Site’yi nasıl
        kullandıklarını anlamak, Site’de en çok hangi sayfaların ziyaret
        edildiğini ve ALOHA reklamlarının ne denli etkili olduğunu tespit etmek,
        Site’de yaşanılan zorlukları kaydetmek, Site tasarımı ve
        kullanışlılığını geliştirmek, Site kullanımı hakkında anonim olarak
        istatistiksel bilgi toplamak amacıyla Google Analytics çerezleri
        kullanılmaktadır.
      </p>
      <h3>
        3. Kişisel Verilerinizi Hangi Amaçla Hangi 3. Kişilere Aktarabiliriz?
      </h3>
      <p>
        Kişisel verileriniz, ALOHA tarafından, <br /> <br />
        Şirketimiz, dijital ortamda hizmet vermekte, bu durum, dijital
        altyapıların işletilmesi farklı hizmet sağlayıcılarla veri akışını
        zaruri kılmaktadır. <br /> <br />
        Verileriniz, yurtdışında mukim sunucularda sakladığından, Açık rızanız
        kapsamında, hizmet sağlayıcımızla Saklama ve Arşiv Faaliyetlerinin
        Yürütülmesi, Veri Sorumlusu Operasyonlarının Güvenliğinin Temini, Bilgi
        Güvenliği Süreçlerinin Yürütülmesi amaçlarıyla aktarılmaktadır. <br />{" "}
        <br />
        Yine, kimlik, iletişim, müşteri işlem kategorisindeki verilerinize,
        İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri
        sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu
        olması”hukuki sebebi kapsamında, Finans Ve Muhasebe İşlerinin
        Yürütülmesi ve Sözleşme Süreçlerinin Yürütülmesi amacıyla
        hissedarlarımız erişebilmektedir. <br /> <br /> Reklam ve hedefleme
        çerezleri vasıtasıyla toplanan kişisel verileriniz, açık rızanız
        kapsamında, hizmet sağlayıcı Google Analytics ile Sözleşme Süreçlerinin
        Yürütülmesi ve Pazarlama ve Analiz Çalışmalarının Yürütülmesi amacıyla
        paylaşılabilir. <br /> <br />
        Şikayet ve talep süreçlerinde toplanan verileriniz ve müşteri işlem
        verileriniz, İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
        kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin
        zorunlu olması”ve Bir hakkın tesisi, kullanılması veya korunması için
        veri işlemenin zorunlu olması hukuki sebepleri kapsamında, Yetkili Kişi,
        Kurum Ve Kuruluşlara Bilgi Verilmesi, Veri sorumlusu operasyonlarının
        güvenliğinin sağlanması amaçlarıyla yetkili kamu kurum ve kuruluşlarla
        paylaşılabilir. <br /> <br />
        Metinde sıralanan tüm kategorilerdeki verileriniz, gerekli olduğu
        ölçüde, İlgili kişinin temel hak ve özgürlüklerine zarar vermemek
        kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin
        zorunlu olması”ve Bir hakkın tesisi, kullanılması veya korunması için
        veri işlemenin zorunlu olması hukuki sebepleri kapsamında, hukuki
        yükümlülüklerin yerine getirilmesi için, avukatlar, denetçiler, adli
        bilişim uzmanları, siber güvenlik danışmanları, vergi danışmanları ile
        danışmanlık ve hizmet aldığımız diğer üçüncü kişilerle paylaşılabilir.
      </p>
      <h3>4. Veri Güvenliği Nasıl Sağlanır?</h3>
      <p>
        ALOHA, ilgili mevzuatta ve/veya ALOHA’nın politikalarında belirlenen
        şartlarda, kişisel verilerinize hukuka aykırı olarak erişilmemesini ve
        verilerin hukuka aykırı olarak işlenmemesini sağlamak için gerekli idari
        ve teknik güvenlik önlemlerini almakta, gerekli denetim ve kontrolleri
        sağlamaktadır. <br />
        <br />
        Ancak, ALOHA, mobil uygulama üzerinden başka uygulamalara ve 3.şahıslara
        link verilmesi halinde, bu uygulamaların gizlilik politikaları ve
        içeriklerine yönelik herhangi bir sorumluluk taşımamaktadır. <br />{" "}
        <br />
        Veri güvenliği hakkındaki sorularınız için ALOHA ile iletişime
        geçebilir, ayrıntılı bilgiye Kişisel Verileri Koruma Politikasından
        ulaşabilirsiniz.
      </p>
      <h3>5. Kişisel Verilerinizi Ne Kadar Süreyle Saklarız?</h3>
      <p>
        ALOHA tarafından toplanan Kişisel Verileriniz, işbu aydınlatma metninde
        belirtilen amaçların gerektirdiği süreler ve mevzuatta belirtilen
        süreler dikkate alınarak belirlenen makul sürelerle saklanır.
      </p>
      <h3>6. Kanun Kapsamındaki Haklarınızı Nasıl Kullanırsınız?</h3>
      <p>
        Kanun’un 11. maddesi, ilgili kişilerin (kişisel verileri işlenen gerçek
        kişilerin) haklarını düzenlemektedir. Bu maddede belirtilen haklarınızı
        kullanmak ve taleplerinizi ALOHA’ya iletmek için aşağıdaki yöntemleri
        tercih edebilirsiniz:
      </p>
      <ul>
        <li>
          Taleplerinizi yazılı olarak Barbaros Mahallesi Begonya Sokak Nidakule
          Ataşehir Batı Blok No:1 İç Kapı No:2 Ataşehir İstanbul adresine
          gönderebilirsiniz.
        </li>
        <li>
          Taleplerinizi güvenli elektronik imza, mobil imza ya da tarafımıza
          daha önce bildirdiğiniz ve sistemimizde kayıtlı bulunan elektronik
          posta adresinizi kullanmak suretiyle{" "}
          <a
            className="text-dark fw-bold"
            rel="noreferrer"
            target="_blank"
            href="mailto: kvkk@alohalive.online"
          >
            {" "}
            kvkk@alohalive.online
          </a>{" "}
          mail adresine iletebilirsiniz.
        </li>
        <li>
          <a
            className="text-dark fw-bold"
            rel="noreferrer"
            target="_blank"
            href="https://www.resmigazete.gov.tr/eskiler/2018/03/20180310-6.htm"
          >
            Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ (“Tebliğ”)
          </a>
          ’de belirtilen diğer yöntemleri tercih edebilirsiniz.
        </li>
      </ul>
      <p>
        Başvurunuzda; ad, soyad ve başvuru yazılı ise imzanızın, Türkiye
        Cumhuriyeti vatandaşları için T.C. kimlik numaranızın, yabancılar için
        uyruğunuzun, pasaport numaranızın veya varsa kimlik numaranızın,
        tebligata esas yerleşim yeri veya iş yeri adresinizin, varsa bildirime
        esas elektronik posta adresi, telefon ve faks numaranızın ve talep
        konunuzun bulunması Tebliğ’in 5/2. maddesi uyarınca zorunlu olup; varsa
        talebinize ilişkin bilgi ve belgelerin başvuruya eklenmesi
        taleplerinizin en sağlıklı şekilde ve kısa bir süre içerisinde
        sonuçlandırılması bakımından önem arz etmektedir. Cevap vermeden önce
        kimliğinizi doğrulama hakkımız saklıdır.
      </p>
      <p>
        {/* <strong>
          <em>Son güncelleme tarihi: 20/06/2022</em>
        </strong> */}
      </p>
      <p>
        <a
          className="text-dark fw-bold"
          rel="noreferrer"
          target="_blank"
          href="mailto: kvkk@alohalive.online"
        >
          {" "}
          kvkk@alohalive.online
        </a>{" "}
        mail adresi yalnızca KVKK’nın 13. maddesi gereğince ilgili kişiler
        tarafından yapılacak başvuruların alınması için açılmış olup bu mail
        adresi üzerinden başkaca bir talebinizi ve/veya iş başvurularınızı
        göndermemenizi rica ederiz.
      </p>
    </div>
  );
};

export default UserInformation;
