import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import UserInformation from "./pages/userInformation";
// import Home from "./pages/home";
//

function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
          {/* <Route path="/" element={<Home />} /> */}
          <Route path="/" element={<UserInformation />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
